$contact-aside-bg-color: #0F722A;

.contact-aside {
  background-color: $contact-aside-bg-color;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8em;
  padding: 0.5em 1.5em 1.5em 1.5em;
  box-shadow: 8px 6px 93px #000000;
  border-radius: 0 31px 31px 0;
  max-width: 500px;
}

.contact-aside > h1 {
  font-size: 2rem;
  margin-top: 1em;
  margin-bottom: 1em;
}

.contact-social-links {
  margin-top: 3em;
  margin-bottom: 3em;
  display: flex;
  align-items: center;
}

.contact-social-links img {
  width: 50px;
  height: 50px;
  margin-right: 1em;
}

.contact-aside-info > svg {
  width: 25px;
  height: 35px;
  margin-right: 0.5em;
}

.contact-aside-info {
  margin-top: 1em;
}

.contact-aside-info {
  display: flex;
  align-items: center;
}

.contact-aside-list {
  list-style: none;
}

@media screen and (min-width: 640px) {
  .contact-aside {
    max-width: 600px;
  }
}

@media screen and (min-width: 800px) {
  .contact-aside {
    max-width: 400px;
    z-index: 10;
  }
}

@media screen and (min-width: 1024px) {
  .contact-aside {
    max-width: 600px;
    position: relative;
    top: 3em;
  }
}