@import '../../constants/colors';

.home h1 {
  padding-top: 1.5em;
  text-align: center;
  font-size: 3rem;
}

.home-services-container {
  position: relative;
}

.home-services-line {
  background-color: rgba(59, 190, 239, 0.2);
  position: absolute;
  height: 90%;
  width: 2px;
  left: 49%;
  bottom: 0;
}

.home-services-title {
  text-shadow: 1px 1px 0 $mainBlue,
    -1px -1px 0 $mainBlue,
    1px -1px 0 $mainBlue,
    -1px 1px 0 $mainBlue,
    1px 1px 0 $mainBlue;
}

.home * {
  font-family: Manrope, sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.tap-to-more-button {
  font-size: 1em;
  margin: 1.5rem;
}

.tap-to-more-button p {
  padding-right: 0.5em;
  font-weight: normal;
}

.quote-button {
  font-size: 1.5rem;
  position: absolute;
  left: 1em;
  bottom: 1em;

  p {
    margin-right: 0.5em;
  }

  svg {
    margin-left: 0.2em;
  }
}

@media screen and (min-width: 640px) {
  .tap-to-more-button {
    font-size: 1.3rem;
  }

  .tap-to-more-button svg {
    width: 40px;
  }
}

@media screen and (min-width: 840px) {
  .tap-to-more-button {
    font-size: 1.5rem;
  }

  .tap-to-more-button svg {
    width: 50px;
  }
}

@media screen and (min-width: 1024px) {
  .tap-to-more-button {
    font-size: 1.8rem;
  }

  .tap-to-more-button svg {
    width: 60px;
  }
}

@media screen and (min-width: 750px) {
  .home h1 {
    font-size: 5rem;
  }
}

@media screen and (max-width: 1024px) {
  .home-services-line {
    display: none;
  }
}

@import 'HomeDigital/HomeDigital';
@import 'HomeTechs/HomeTechs';
@import 'HomeBlog/HomeBlog';
@import 'HomeEnd/HomeEnd';