.home-end {
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-end > h1 {
  color: $mainGreen;
  max-width: 1100px;
  font-size: 1.5rem;
  padding: 1em;
}

.home-end > h3 {
  color: white;
  margin-top: 1em;
  margin-bottom: 1em;
}

@media screen and (min-width: 640px) {
  .home-end > h1 {
    font-size: 2rem;
  }

  .home-end > h3 {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .home-end > h1 {
    font-size: 3rem;
  }

  .home-end > h3 {
    font-size: 2rem;
  }
}