.home-digital-header {
  margin-top: 6em;
}

.home-digital-title {
  text-shadow:
          1px 1px 0 $mainGreen,
          -1px -1px 0 $mainGreen,
          1px -1px 0 $mainGreen,
          -1px 1px 0 $mainGreen,
          1px 1px 0 $mainGreen;
}

.home-digital-carousel {
  position: relative;
  margin-top: 6em;
  display: flex;
  justify-content: space-evenly;
  height: 60vh;
}

.middle-card, .left-card, .right-card {
  position: relative;
}

.right-card {
  display: none;
  order: 3;
  opacity: 0.3;
  bottom: 0;
}

$delay: 0.2s;

.middle-card {
  transition: bottom $delay ease-in-out, opacity $delay ease-in-out;
  order: 2;
  bottom: 2em;
  opacity: 1;
}

.left-card {
  display: none;
  order: 1;
  opacity: 0.3;
  bottom: 0;
}

.left-card, .right-card {
  z-index: -10;
}

.left-arrow, .right-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 20;
}

.left-arrow {
  left: 0;
  transform: rotate(180deg);
}

.right-arrow {
  right: 0;
}

@media screen and (max-width: 540px)  {
  .right-arrow, .left-arrow {
    top: auto;
  }
}

@media screen and (min-width: 750px) {
  .right-card {
    display: block;
    right: 3em;
  }
}

@media screen and (min-width: 1150px) {
  .left-card {
    display: block;
    left: 3em;
  }
}

@media screen and (min-width: 1400px) {
  .home-digital-carousel {
    margin-right: 5em;
    margin-left: 5em;
  }

  .left-card {
    left: 5em;
  }

  .right-card {
    right: 5em;
  }
}

@import './DigitalCarouselCard';