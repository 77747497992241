.outerCircle {
  width: 96px;
  height: 96px;
  background: radial-gradient(42.36% 42.36% at 50% 50%, rgba(21, 153, 57, 0) 0%, rgba(255, 255, 255, 0.54) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
}

.outerCircle, .innerCircle {
  border-radius: 50%;
}

.innerCircle {
  width: 62px;
  height: 62px;
  background: radial-gradient(42.36% 42.36% at 50% 50%, rgba(21, 153, 57, 0.4806) 0%, rgba(255, 255, 255, 0.54) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.number {
  color: white;
  font-size: 1.2rem;
}

@media screen and (min-width: 640px) {
  .outerCircle {
    width: 144px;
    height: 144px;
    margin-left: 0;
  }

  .innerCircle {
    width: 96px;
    height: 96px;
  }

  .number {
    font-size: 1.5rem;
  }
}