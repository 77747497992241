.circleHolder {
  position: fixed;
  z-index: 60;
  bottom: 3rem;
  right: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 4.5rem;
}

.centerCircle {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 9;
  position: relative;
}

.littleCircleHolder {
  width: calc(100% + 3em);
  height: calc(100% + 3rem);
  position: absolute;
  border-radius: 50%;
  animation: animation 18s linear infinite;
}

.littleCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  background: red;
  border-radius: 50%;
  top: 0;
  animation: ianimation 18s linear infinite;
}

.littleCircleHolder:nth-child(3) {
  animation-name: animation2;
}

.littleCircleHolder:nth-child(4) {
  animation-name: animation3;
}

.littleCircleHolder:nth-child(5) {
  animation-name: animation4;
}

.littleCircleHolder:nth-child(6) {
  animation-name: animation5;
}

.littleCircleHolder:nth-child(7) {
  animation-name: animation6;
}

.littleCircleHolder:nth-child(8) {
  animation-name: animation7;
}

.littleCircleHolder:nth-child(3) .littleCircle {
  animation-name: ianimation2;
}

.littleCircleHolder:nth-child(4) .littleCircle {
  animation-name: ianimation3;
}

.littleCircleHolder:nth-child(5) .littleCircle {
  animation-name: ianimation4;
}

.littleCircleHolder:nth-child(6) .littleCircle {
  animation-name: ianimation5;
}

.littleCircleHolder:nth-child(7) .littleCircle {
  animation-name: ianimation6;
}

.littleCircleHolder:nth-child(8) .littleCircle {
  animation-name: ianimation7;
}

@keyframes animation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animation2 {
  0% {
    transform: rotate(calc(60deg * 1));
  }
  100% {
    transform: rotate(calc((1 * 60 + 360) * 1deg));
  }
}

@keyframes animation3 {
  0% {
    transform: rotate(calc(60deg * 2));
  }
  100% {
    transform: rotate(calc((2 * 60 + 360) * 1deg));
  }
}

@keyframes animation4 {
  0% {
    transform: rotate(calc(60deg * 3));
  }
  100% {
    transform: rotate(calc((3 * 60 + 360) * 1deg));
  }
}

@keyframes animation5 {
  0% {
    transform: rotate(calc(60deg * 4));
  }
  100% {
    transform: rotate(calc((4 * 60 + 360) * 1deg));
  }
}

@keyframes animation6 {
  0% {
    transform: rotate(calc(60deg * 5));
  }
  100% {
    transform: rotate(calc((5 * 60 + 360) * 1deg));
  }
}

@keyframes animation7 {
  0% {
    transform: rotate(calc(60deg * 6));
  }
  100% {
    transform: rotate(calc((6 * 60 + 360) * 1deg));
  }
}

@keyframes ianimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes ianimation2 {
  0% {
    transform: rotate(calc(60deg * -1));
  }
  100% {
    transform: rotate(calc((1 * 60 + 360) * -1deg));
  }
}

@keyframes ianimation3 {
  0% {
    transform: rotate(calc(60deg * -2));
  }
  100% {
    transform: rotate(calc((2 * 60 + 360) * -1deg));
  }
}

@keyframes ianimation4 {
  0% {
    transform: rotate(calc(60deg * -3));
  }
  100% {
    transform: rotate(calc((3 * 60 + 360) * -1deg));
  }
}

@keyframes ianimation5 {
  0% {
    transform: rotate(calc(60deg * -4));
  }
  100% {
    transform: rotate(calc((4 * 60 + 360) * -1deg));
  }
}

@keyframes ianimation6 {
  0% {
    transform: rotate(calc(60deg * -5));
  }
  100% {
    transform: rotate(calc((5 * 60 + 360) * -1deg));
  }
}

@keyframes ianimation7 {
  0% {
    transform: rotate(calc(60deg * -6));
  }
  100% {
    transform: rotate(calc((6 * 60 + 360) * -1deg));
  }
}


.facebook {
  background: #1877f2
}

.twitter {
  background: #1da1f2
}

.instagram {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

.linkedin {
  background: #0a66c2;
}

.youtube {
  background: #ff0000;
}

.whatsapp {
  background: #25D366;
}

.littleCircle svg {
  filter: invert(1);
}

.whatsapp svg {
  width: 40px;
}

.twitter svg {
  width: calc(1em + 1px);
}

.facebook svg {
  width: calc(1em + 1px);
}

.instagram svg {
  width: calc(1em + 1px);
}

.youtube svg {
  width: 20px;
}

.linkedin svg {
  width: 20px;
}

.wp-button {
  border-radius: 50%;
  visibility: visible !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 50%);
}

.wp-button > * {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}