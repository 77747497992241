@import '../../constants/colors';

.container {
  border: 3px solid #159939;
  border-radius: 204px;
  display: flex;
  justify-content: center;
  width: 80vw;
  padding: 2em;
  transition: all 0.6s;
}

.container:hover {
  cursor: pointer;
  background-color: white;
}

.time {
  font-size: 2rem;
  font-weight: bold;
  color: $mainGreen;
  margin: 0;
}

@media screen and (min-width: 640px) {
  .container {
    width: 60vw;
  }

  .time {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    width: 40vw;
  }

  .time {
    font-size: 4rem;
  }
}
