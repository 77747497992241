@import '../../../constants/colors.scss';

.blueText,
.green-text {
  font-weight: bold;
}

.blueText {
  color: $mainBlue;
}

.green-text {
  color: $mainGreen
}

.video {
  position: absolute;
  width: 100%;
  object-fit: cover;
  height: 100vh;
  z-index: -1;
  filter: brightness(60%);
}

.introduction {
  width: 100%;
  height: 100vh;
  padding-top: 4rem;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  p {
    color: white;
  }

  .introductionWrapper {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid rgba(white, 0.64);
    padding: 8rem 14rem;
    border-radius: 18.375em;

    .content {

      .head {
        padding: 0;
        color: $mainBlue;
        font-size: 6rem;
        font-weight: 400;
        text-align: center;
        margin: 0;
      }

      .subhead {
        color: white;
        font-size: 1.5rem;
        text-align: center;
      }

      .greenText {
        color: $mainGreen;
        font-size: 5rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .introduction {
    .introductionWrapper {
      padding: 6rem 12rem;
    }
  }
}

@media screen and (max-width: 916px) {
  .introduction {
    .introductionWrapper {
      padding: 4rem 10rem;
    }
  }
}

@media screen and (max-width: 850px) {
  .introduction {
    .introductionWrapper {
      padding: 2rem 8rem;
    }
  }
}


@media screen and (max-width: 783px) {
  .introduction {
    .introductionWrapper {
      padding: 2rem 8rem;

      .content {
        .head {
          color: $mainBlue;
          font-size: 3rem;
          font-weight: 400;
          text-align: center;
          margin: 0;
        }

        .subhead {
          font-size: 1rem;
        }

        .greenText {
          font-size: 2.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .introduction {
    .introductionWrapper {
      padding: 3rem 6rem;
    }
  }
}

@media screen and (max-width: 440px) {
  .introduction {
    .introductionWrapper {
      padding: 4rem;
    }
  }
}