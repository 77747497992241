@import "../../constants/colors";

.web-development *,
.mobile-development *,
.artificial-intelligence *,
.cyber-security *,
.digital-marketing *,
.services-nav * {
  font-family: "Manrope", "sans-serif";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.services-container h1 {
  color: $mainBlue;
  font-size: 2rem;
  margin-bottom: 0.5em;
  padding: 0.5em;
}

.services-element {
  color: white;
}

.services-element .services-element-content {
  margin: 3em;
  max-width: 350px;
  font-size: 1.3rem;
}

.services-image-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.services-why-list {
  color: white;
}

.services-why-list li {
  margin-bottom: 1em;
}

.containerLine {
  display: flex;
  position: relative;
}

.mySvg {
  width: 100%;
}

.containerDiv {
  position: relative;
}

.containerDiv div {
  position: absolute;
  background-color: aqua;
  width: 300px;
  height: 300px;
  z-index: 10;
  left: 50px;
  bottom: 52%;
  display: block;
  visibility: hidden;
}

.containerDiv div:hover {
  visibility: visible;
  background-color: aquamarine;
}

@media screen and (min-width: 500px) {
  .services-element p {
    max-width: 450px;
  }

  .services-why-list {
    padding: 3em;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 640px) {
  .services-element {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 1em;
  }

  .digital-element {
    width: 100vw;
    max-width: 1200px;
  }

  .ai-element {
    justify-content: space-between;
  }

  .services-container h1 {
    font-size: 3rem;
    margin-bottom: 0;
  }

  .services-element p {
    max-width: 700px;
  }
}

@media screen and (min-width: 1024px) {
  .services-why-list {
    font-size: 2rem;
  }

  .services-element {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 640px) {
  .services-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .services-element {
    padding: 1em;
  }
}

@import "./Services-Nav";
@import "Service-Images";