
.home-blog * {
  font-family: Manrope, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home-blog > div {
  margin-top: 5em;
}

.home-blog-title {
  text-shadow: 1px 1px 0 white,
  -1px -1px 0 white,
  1px -1px 0 white,
  -1px 1px 0 white,
  1px 1px 0 white;
}
