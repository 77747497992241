@import "../../constants/colors";
@import "./Privacy-Card";
@import "./Privacy-Board";
@import "./Privacy-Section";

.privacy-policy {
  display: flex;
  flex-direction: column;
  align-items: center;  
  padding: 1.5em;
}

.privacy-policy > * {
  font-family: Manrope, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.privacy-policy h1, h3 {
  color: $mainGreen;
}

.privacy-policy h1 {
  font-size: 3rem;
  margin-top: 2em;
  margin-bottom: 0.3em;
}

.privacy-policy h3 {
  font-size: 1.3rem;
}

.privacy-hero-text {
  color: white;
  max-width: 900px;
  margin-bottom: 1.5em;
}

@media screen and (min-width: 640px) {
  .privacy-policy h1 {
    font-size: 4rem;
  }

  .privacy-policy h3 {
    font-size: 2rem;
  }

  .privacy-hero-text {
    font-size: 1.2rem;
  }
}



