
.tech {
  background: rgba(219, 212, 212, 0.5);
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.15);
  width: 102px;
  height: 102px;
  padding: 1em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1em;
}

.tech-icon {
  height: 60px;
}
