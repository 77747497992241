.container {
  font-family: 'Heebo', sans-serif;
}

.image {
  width: 200px;
}

.name {
  font-size: 1.5rem;
  font-weight: bold;
}

.job {
  font-weight: 300;
}

.job, .name {
  margin: 0;
}

.content {
  color: white;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 350px;
}
