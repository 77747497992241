.contact-form {
    box-shadow: 8px 6px 93px #000000;
  }

@media screen and (min-width: 800px) {
    .contact-form {
        padding-left: 10em;
        position: relative;
        top: 7em;
        right: 8em;
        width: 100vw;
    }
}

@media screen and (min-width: 1024px) {
    .contact-form {
      padding-left: 20em;
      right: 17em;
      width: clamp(100px,100vw,900px);
    }
  }