.container * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Heebo, sans-serif;
}

.container {
  width: 50px;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 4px;

  &:hover {
    cursor: pointer;
  }
}

.flag {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 4px;
}


.language {
  font-size: 1rem;
  padding-right: 4px;
}