.digital-carousel-card {
  background-color: white;
  max-width: 380px;
  border-radius: 22px;
  position: relative;
  transition: all 0.3s;
}

.digital-carousel-active-card {
  position: relative;
  opacity: 0.5;
  bottom: 0;
}

.digital-carousel-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 3em 1.5em;
}

.digital-carousel-card-wrapper > h1 {
  position: absolute;
  left: 0;
  top: -2.4em;
  color: $mainGreen;
  font-size: 5rem;
  font-weight: 500;
  letter-spacing: 0.075em;
}

.digital-carousel-card-wrapper > div {
  display: flex;
  align-items: center;
}

.digital-carousel-card-wrapper > div > svg {
  margin-right: 1em;
}

.digital-carousel-card-content {
  max-width: 250px;
}

.digital-carousel-card-content h2 {
  font-size: 1.3rem;
}

.digital-carousel-card-content p {
  margin-top: 1em;
  font-size: 1rem;
}

.digital-carousel-card-button  p {
  margin-right: 0.5em;
  font-size: 0.9rem;
}