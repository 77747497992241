$green: #00bc9e;
$blue: #202e3a;

@mixin absolute {
  position: absolute;
}

@mixin center-x {
  left: 50%;
  transform: translateX(-50%);
}

.error *, .error *:before, .error *:after {
  box-sizing: border-box;
  margin: 0;
  padding-top: 0;
}

.error {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Marope, sans-serif;
}

.error-text {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: $green;
    font-size: 10rem;
    font-weight: bold;
  }
}

.machine {
  position: relative;
  width: 191px;
  height: 311px;
  -webkit-box-reflect: below 0px -webkit-linear-gradient(bottom, rgba(255, 255, 255, 0.4) 0%, transparent 50%, transparent 100%);
  transform: scale(.75);
}

.machine-top {
  @include absolute;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  width: 119px;
  height: 164px;
  background: linear-gradient($green, $green 20%, #008e77 20%, #008e77 22%, $green 22%, $green 62%, #008e77 62%, #008e77 64%, $green 64%);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;

  &:before {
    content: "";
    @include absolute;
    left: 50%;
    top: -10px;
    transform: translateX(-50%);
    width: 45px;
    height: 10px;
    background: linear-gradient(to right, #008e77 15%, #40aa99 15%, #40aa99 45%, #008e77 45%, #008e77 85%, #00806b 85%, #00806b);
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }

  &:after {
    content: "";
    @include absolute;
    width: 100%;
    height: 100%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background: linear-gradient(to right, transparent, transparent 8%, rgba(255, 255, 255, .2) 8%, rgba(255, 255, 255, .2) 22%, transparent 22%, transparent 88%, transparentize(#008e77, .5) 88%);
  }
}

.machine-middle {
  @include absolute;
  bottom: -8px;
  width: 100%;
  height: 8px;
  background: $blue;
  border-bottom-right-radius: 35px;
  border-bottom-left-radius: 35px;

  &:after {
    content: "";
    @include absolute;
    left: 10px;
    bottom: -18px;
    width: 142px;
    height: 18px;
    background: linear-gradient(to right, $blue, $blue 3%, #414d57 3%, #414d57 15%, $blue 15%, $blue);
    border-top-right-radius: 6px;
  }

  &:before {
    content: "";
    @include absolute;
    left: 52%;
    transform: translateX(-50%);
    bottom: -35px;
    width: 98px;
    height: 10px;
    background: linear-gradient(to right, $blue, $blue 3%, #414d57 3%, #414d57 15%, $blue 15%, $blue);
  }
}

.machine-bottom {
  @include absolute;
  @include center-x;
  bottom: 20px;
  z-index: 10;
  width: 121px;
  height: 78px;
  border-radius: 15px;
  border: 5px solid #d5d5d5;
  border-top-color: transparent;

  &:before {
    content: "";
    @include absolute;
    @include center-x;
    top: -1px;
    width: 110%;
    height: 10px;
    background: linear-gradient(to right, $blue, $blue 3%, #414d57 3%, #414d57 15%, $blue 15%, $blue);
  }

  &:after {
    content: "";
    @include absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 59px;
    background: linear-gradient(to right, transparent, transparent 10%, rgba(255, 255, 255, .3) 10%, rgba(255, 255, 255, .3) 25%, rgba(255, 255, 255, .1) 25%, rgba(255, 255, 255, .1) 85%, rgba(0, 0, 0, .1) 85%, rgba(0, 0, 0, .1));
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.hand {
  @include absolute;
  right: 7px;
  bottom: 25px;
  z-index: -1;
  width: 38px;
  height: 69px;
  border: 10px solid #202e3a;
  border-left-color: transparent;
  border-radius: 0 18px 18px 0;

  &:after {
    content: "";
    @include absolute;
    left: -95px;
    top: 22px;
    width: 17px;
    height: 1px;
    background: transparentize(#b3b3b3, .3);
    color: #b3b3b3;
    box-shadow: 0 5px 0 0 currentColor, 0 10px 0 0 currentColor, 0 15px 0 0 currentColor, 0 20px 0 0 currentColor;
  }

  &:before {
    content: "";
    @include absolute;
    left: -105px;
    bottom: -20px;
    width: 93px;
    height: 7px;
    background: linear-gradient(to right, $blue, $blue 3%, #414d57 3%, #414d57 15%, $blue 15%, $blue);
  }
}

.left-hand {
  @include absolute;
  top: 125px;
  z-index: -1;
  width: 45px;
  height: 184px;
  border: 8px solid #d5d5d5;
  border-right-color: transparent;
  border-radius: 22px 0 0 22px;

  &:after {
    content: "";
    @include absolute;
    top: -14px;
    left: 21px;
    width: 7px;
    height: 23px;
    background: #008e77;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &:before {
    content: "";
    @include absolute;
    top: -11px;
    left: 13px;
    width: 8px;
    height: 15px;
    background: linear-gradient(#00bc9e, #00bc9e 60%, #40cdb6 60%, #40cdb6);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}

.coffee {
  @include absolute;
  @include center-x;
  bottom: 20px;
  z-index: 1;
  width: 121px;
  height: 20px;
  border-radius: 0 0 15px 15px;
  background: white;

  &:after {
    content: "";
    @include absolute;
    left: -11px;
    bottom: -22px;
    width: 144px;
    height: 16px;
    background: linear-gradient(to right, #00bc9e, #00bc9e 3%, #40cdb6 3%, #40cdb6 15%, #00bc9e 15%, #00bc9e);
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom: 2px solid #00a98e;
  }

  &:before {
    content: "";
    @include absolute;
    left: 54px;
    top: -41px;
    width: 5px;
    height: 4px;
    background: white;
    border-radius: 50%;
    box-shadow: 3px 10px 0 1px white;
    animation: animatecoffee 2s linear infinite;
  }
}

@keyframes animatecoffee {
  to {
    top: 0;
  }
}

.test {
  margin: 50px;
  width: 20px;
  height: 120px;
  border: 6px solid red;
  border-radius: 15px 0 0 15px;
  border-right-color: transparent;
}