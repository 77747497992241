.project-additional-data {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.project-additional-data,
.project-navigation {
    padding: 3em 2em;
}

.project-additional-data h2 {
    font-weight: normal;
    margin-bottom: 2em;
    text-align: start;
}

.project-additional-data>.project-additional-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
}

.project-additional-data ul {
    list-style: none;

    li {
        margin-top: 1em;
    }
}

.project-additional-data-first-list {
    color: gray;
}


@media screen and (min-width: 640px) {

    .project-additional-data,
    .project-navigation {
        padding: 5em 7em;
    }

    .project-additional-data ul {
        font-size: 1.2rem;
    }
}

@media screen and (min-width: 790px) {

    .project-additional-data,
    .project-navigation {
        padding: 5em 14em;
    }
}