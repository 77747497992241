// @import "bourbon";

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: hidden;
  
  // Modernizr no-js fallback
  .no-js & {
    display: none;
  }
}

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ffffff;
  animation: spin 1.2s linear infinite;
  z-index: 11;
  
  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #159939;
    animation: spin-reverse .6s linear infinite;
  }
  
  &:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #3bbeef;
    animation: spin 1s linear infinite;
  }
}

// Bourbon mixins
@keyframes spin {
  0% { 
     transform: rotate(0deg);
  }
  100% {
     transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  0% { 
    transform: rotate(0deg);
 }
 100% {
    transform: rotate(-360deg);
 }
}

#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: #000;
  z-index: 10;
}

#loader-wrapper .loader-section.section-left {
  left: 0;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}
