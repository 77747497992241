.container {
  display: flex;
  justify-content: space-evenly;
}

.content {
  color: white;
  max-width: 700px;
  margin-left: 3em;
  position: relative;
}

.text {
  padding-right: 1em;
  font-size: 1rem;
  font-weight: bold;
}

.title {
  font-size: 1.2rem;
  margin-bottom: 0.3em;
}

.image {
  width: 250px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.listStyle {
  position: relative;
}

.line {
  width: 8px;
  background-color: rgba(255, 255, 255, 0.54);
  margin-top: 0;
  z-index: 100;
  margin-left: 0.5em;
  left: 41%;
  height: calc(100% + 3em);
  position: absolute;
}

.point {
  position: relative;
}

@media screen and (min-width: 800px) {
  .container {
    margin-left: 15%;
    justify-content: normal;
  }

  .line {
    height: 94%;
  }

  .title {
    font-size: 2rem;
  }

  .text {
    font-size: 1.5rem;
  }

  .image {
    right: 20%;
    top: 54%;
  }
}