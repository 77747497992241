@import 'Tech';

$yellow: #989B09;

.home-techs-title {
  text-shadow:
          1px 1px 0 $yellow,
          -1px -1px 0 $yellow,
          1px -1px 0 $yellow,
          -1px 1px 0 $yellow,
          1px 1px 0 $yellow;
}

.home-techs {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-techs > p {
  color: white;
  font-size: 1.3rem;
  max-width: 1000px;
  margin: 3em;
}