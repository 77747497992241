@import "react-multi-carousel/lib/styles.css";

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: black;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(360deg,
      rgba(16, 16, 16, 1) 0%,
      rgba(21, 153, 57, 1) 50%,
      rgba(59, 190, 239, 1) 100%);
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(360deg,
      rgba(16, 16, 16, 1) 0%,
      rgba(21, 153, 57, 1) 50%,
      rgba(59, 190, 239, 1) 100%);
}

.multi-carousel-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.global-services-image {
  animation: image-animation 1s ease-in-out forwards;
}

@keyframes image-animation {
  from {
    transform: translateY(-40px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.react-multiple-carousel__arrow,
.react-multiple-carousel__arrow--right {
  z-index: 10;
}