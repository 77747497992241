.privacy-board {
  display: grid;
  grid-template-columns: repeat(3,minmax(0,110px));
  grid-template-rows: repeat(6,minmax(0,110px));
  gap: 10px;
}

.privacy-board-content {
  background-color: white;
  border-radius: 9px;
  grid-column: 1 / 4;
  grid-row: 4 / 7;
  overflow-y: scroll;
}

.privacy-board-content-wrapper {
  width: 100%;
}

@media screen and (min-width: 500px) {
  .privacy-board {
    grid-template-columns: repeat(3,minmax(0,140px));
    grid-template-rows: repeat(5,minmax(0,140px));
  }

  .privacy-board-content {
    grid-row: 4 / 6;
  }
}

@media screen and (min-width: 640px) {
  .privacy-board {
    grid-template-columns: repeat(3,minmax(0,190px));
    grid-template-rows: repeat(5,minmax(0,190px));
  }
}

@media screen and (min-width: 1024px) {
  .privacy-board {
    grid-template-columns: repeat(5,minmax(0,190px));
    grid-template-rows: repeat(3,minmax(0,190px));
  }

  .privacy-board-content {
    grid-column: 2 / 5;
    grid-row: 2 / 4;
  }
}

@media screen and (min-width: 1440px) {
  .privacy-board {
    grid-template-columns: repeat(5,minmax(0,230px));
    grid-template-rows: repeat(3,minmax(0,230px));
  }
}