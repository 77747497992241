$contact-map-width: 100%;
$contact-map-height: 70vh;

.contact-map-router {
  text-align: right;
  width: $contact-map-width;
  height: $contact-map-height;
}

.contact-map {
  overflow: hidden;
  background: none;
  height: $contact-map-height;
  width: $contact-map-width;
  border-bottom-right-radius: 20%;
  border-bottom-left-radius: 20%;
}

.contact-map > iframe {
  width: $contact-map-width;
  height: $contact-map-height;
}
@media screen and (max-width: 991px){
  .contact-map {
    border-bottom-right-radius: 25%;
    border-bottom-left-radius: 25%;
  }
}