.custom-button {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 300px;
  border: none;
  background-color: inherit;
  transition: all 0.3s;
  font-weight: bold;

  &:hover {
    cursor: pointer;

    .button-move {
      position: relative;
      transform: translateX(10px);
    }
  }
}

.custom-button * {
  transition: all 0.3s;
}

.button-move {
  display: flex;
  align-items: center;
}


.button-circle, .button-arrow {
  width: 40px;
}

.button-circle {
  position: relative;
  right: 1em;
}

.button-arrow {
  margin-left: 0.5em;
}