.blog-aside {
  font-family: 'DM Sans', sans-serif;
}

.blog-aside .search {
  display: flex;
  align-items: center;
}

.blog-aside-title {
  color: #159939;
}

