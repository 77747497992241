.privacy-section h2 {
  color: $mainGreen;
  text-align: center;
  font-weight: bold;
}

.privacy-section div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.privacy-section p {
  font-size: 13px;
  padding: 0.5em;
}

.privacy-section img {
  max-width: 80px;
}

@media screen and (min-width: 500px) {
  .privacy-section {
    padding: 1.5em;
    display: grid;
    grid-template-rows: 60px 1fr;
  }

  .privacy-section div {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
  }

  .privacy-section div p {
    max-width: 300px;
  }

  .privacy-section h2 {
    text-align: left;
  }

  .privacy-section img {
    max-width: 100px;
  }
}

@media screen and (min-width: 640px) {
  .privacy-section div p {
    font-size: 1rem;
  }

  .privacy-section h2 {
    text-align: left;
  }

  .privacy-section img {
    max-width: 300px;
  }
}

@media screen and (min-width: 1024px) {
  .privacy-section img {
    width: 150px;
  }
}

@media screen and (min-width: 1440px) {
  .privacy-section div p {
    max-width: 400px;
    font-size: 1.2rem;
  }
}