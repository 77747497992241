.project-contact {
    background-color: black;
    width: 100%;
    padding: 3em;
}

.project-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.project-contact>h2 {
    color: white;
    margin-bottom: 3em;
}

.project-contact-icon {
    width: 100%;
    max-width: 600px;
    height: 600px;
    background-size: cover;
    background-position: center;
}

.project-contact form {
    padding: 2em 3em;
}

.project-contact button {
    margin-top: 1em;
}

.project-contact-form {
    width: 100%;
    max-width: 700px;
}

@media screen and (min-width: 900px) {
    .project-wrapper {
        flex-direction: row;
    }

    .project-contact-icon {
        margin-right: 3em;
        margin-bottom: 0;
    }
}