@import "../../constants/colors";

.faq-container * {
  font-family: Manrope, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-container h1,
.faq-element-title {
  color: $mainBlue;
}

.faq-container h1 {
  margin-top: 1em;
  font-size: 6rem;
}

.faq-element h2 {
  position: absolute;
  z-index: -10;
  opacity: 0.4;
  text-shadow:
    1px 1px 0 $mainBlue,
    -1px -1px 0 $mainBlue,
    1px -1px 0 $mainBlue,
    -1px 1px 0 $mainBlue,
    1px 1px 0 $mainBlue;
  font-size: 16rem;
}

.number-left {
  left: 0.2em;
}

.number-right {
  right: 0.3em;
}

.faq-element {
  display: flex;
  align-items: center;
  padding: 3em;
}

.faq-element>div>p {
  color: white;
  max-width: 600px;
}

.faq-element-reverse {
  flex-direction: row-reverse;
}

.faq-element ul {
  padding-left: 1em;
  color: white;
  list-style: inside;
}

.faq-element li {
  margin-bottom: 0.3em;
  max-width: 250px;
}

.faq-element-title {
  font-size: 1.5rem;
}

@media screen and (min-width: 640px) {
  .faq-main-section {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .faq-element {
    position: relative;
  }

  .faq-element ul {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 0.6fr));
  }
}