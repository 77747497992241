.blog-container {
  font-family: 'Manrope', sans-serif;
  margin-left: 1em;
  margin-right: 1em;
}

.blog-container hr {
  border: none;
  opacity: 0.2;
  height: 0.5px;
  background-color: white;
}

.blog-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 6em;
}

.blog-profile {
  color: white;
  position: relative;
}

.blog-hero-circle {
  width: 1vw;
  height: 4vh;
  padding: 8em 10em 10em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.blog-profile-image {
  max-width: 140px;
  min-width: 80px;
}

.blog-links-list {
  margin-right: 2.3em;
}

.blog-links-list li span{
  margin-top: 2rem;
}

.blog-links-list li img {
  width: 45px;
  height: 45px;
  margin-right: 1.3em;

  &:hover {
    cursor: pointer;
  }
}

.blog-profile-profession, .blog-topic {
  margin: 0;
  width: 210px;
  text-align: center;
}

.blog-topic {
  font-size: 1.5em;
  font-weight: bold;
  color: white;
  width: 100%;
  max-width: 300px;

  span {
    color: $mainGreen;
  }
}

.blog-topic-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  top: -1.5em;
}

.blog-profile-name {
  position: absolute;
  margin: 0;
  font-size: 5rem;
  opacity: 0.1;
  letter-spacing: 0.03em;
  font-weight: bold;
}

.first-part {
  top: 0;
  left: -0.8em;
}

.second-part {
  right: -1.2em;
  bottom: 0;
}

@media screen and (min-width: 640px) {
  .blog-profile-name {
    font-size: 6rem;
  }

  .blog-container {
    margin-right: 3em;
    margin-left: 3em;
  }
}

@media screen and (max-width: 540px) {
  .blog-profile-name {
    font-size: 4em;
  }

  .first-part {
    left: -0.5em;
  }

  .second-part {
    right: -0.6em;
  }
}

@media screen and (max-width: 400px) {
  .blog-profile-name {
    font-size: 4em;
  }

  .first-part {
    left: -0.8em;
  }

  .second-part {
    right: -1.1em;
  }

  .blog-hero-circle {
    height: 4vh;
    padding: 6em 6em 6em;
  }

  .blog-profile-image {
    width: 80px;
  }
}