.services-image {
  max-width: 250px;
  margin-bottom: 1em;
  margin-top: 1em;
}

.ai-image {
  width: 90vw;
  position: relative;
}

.ai-image-right {
  right: -5%;
}

.ai-image-left {
  left: -5%;
}

@media screen and (min-width: 500px) {
  .services-image {
    max-width: 300px;
  }

  .ai-image {
    width: 80vw;
    position: relative;
  }

  .ai-image-right {
    right: -11%;
  }

  .ai-image-left {
    left: -11%;
  }
}

@media screen and (min-width: 640px) {
  .services-image {
    margin-right: 0.75em;
    margin-left: 0.75em;
  }

  .ai-image {
    width: clamp(100px,60vw,700px);
    position: relative;
  }

  .ai-image-right {
    right: 0%;
  }

  .ai-image-left {
    left: -5%;
  }
}

@media screen and (min-width: 1024px) {
  .services-image {
    max-width: 450px;
  }

  .cyber-security-image {
    max-width: 300px  ;
  }
}