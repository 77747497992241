@import "../../constants/colors.scss";
@import "./BlogHero.scss";
@import "./BlogContent.scss";
@import "./BlogAside.scss";
@import "RelatedPosts.scss";

.blog-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
}
.blog-list li span {
  margin-right: 0.5rem;
  padding-bottom: 4rem;
  font-size: 1.2rem;
}

.back-circle {
  background-image: url("../../assets/UI.png");
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (min-width: 640px) {
  .blog-main {
    gap: 5em;
    display: grid;
    grid-template-columns: minmax(0, 0.5fr) minmax(0, 1fr);
  }

  .blog-aside, .blog-content {
    grid-row: 1;
  }

  .blog-content {
    grid-column: 2;
  }

  .blog-aside {
    grid-column: 1;
  }
}