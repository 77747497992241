@import "../../constants/colors";

.contact * {
  font-family: 'Manrope', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.contact a {
  text-decoration: none;
  color: white;
}

.contact {
  display: flex;
  flex-direction: column;
}

.contact > h1 {
  color: $mainGreen;
  font-size: 3rem;
  margin-top: 2em;
  margin-bottom: 1em;
}

@media screen and (min-width: 800px) {
  .contact-main-section {
    display: flex;
  }

  .contact > h1 {
    margin-left: 1em;
    margin-top: 3em;
  }
}

@media screen and (max-width: 800px) {
  .contact {
    align-items: center;
  }
}


@import "./ContactAside";
@import "./ContactMap";