$color: rgba(white, 0.5);

.container, .container * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
  animation: container-animation 0.3s ease-in-out forwards;

  border-bottom: 1px solid $color;
  padding: 0.5em 1em;

  &:hover {
    cursor: pointer;
  }
}

.container p {
  color: $color;
}

@keyframes container-animation {
  from {
    opacity: 0;
    transform: translateY(0);
  }

  to {
    opacity: 1;
    transform: translateY(-3px);
  }
}