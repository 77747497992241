@import "../../constants/colors";

.about * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Manrope, sans-serif;
}

.about {
  color: white;
  display: flex;
  justify-content: center;
}

.about h1 {
  padding-left: 0.3em;
  color: $mainGreen;
}

.about-main-introduction {
  display: flex;
  justify-content: center;
}

.about-title {
  font-size: 3rem;
}

@media screen and (min-width: 1024px) {
  h1 {
    font-size: 4rem;
  }
}

@import 'AboutIntroduction/AboutIntroduciton';
@import 'AboutTeam/AboutTeam';
@import 'AboutReasons/AboutReasons';