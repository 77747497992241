@import '../../constants/colors.scss';


.project, .project * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Manrope, sans-serif;
}

.project {
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.project-empty {
    width: 100%;
    height: 120vh;
}

.project-container {
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
}

@media screen and (min-width: 640px) {
    .project h2 {
        font-size: 1.5rem;
    }
}

@import './ProjectHero/ProjectHero.scss';
@import './ProjectAdditionalData/ProjectAdditionalData.scss';
@import './ProjectSection/ProjectSection.scss';
@import './ProjectsNavigation/ProjectsNavigation.scss';
@import './ProjectContact/ProjectContact.scss';