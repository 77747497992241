@import '../../../constants/colors.scss';

.container {
    color: white;
}

.container>img {
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.container>div {
    display: flex;
    align-items: center;
    padding-left: 4em;
    padding-right: 4em;
    margin-top: 2em;
    margin-bottom: 3em;
}

.text {
    max-width: 500px;
}

.buttonBox p {
    padding-right: 1em;
}

.buttonBox,
.categories {
    display: flex;
    justify-content: flex-end;
}

.text>.title {
    color: white;
    font-size: 2.5rem;
    font-weight: normal;
}

.text>div {
    margin-top: 2em;
    margin-bottom: 2em;
}

.text h2 {
    font-size: 1.2rem;
}

.categories {
    display: flex;
    color: rgba($color: white, $alpha: 0.3);
    position: relative;
    top: 1em;
}

@media screen and (min-width: 850px) {
    .container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }


    .text>h2 {
        margin-bottom: 0;
    }

    .container>div {
        margin-top: 0;
        margin-bottom: 0;
    }
}