.project-navigation {
    background-color: #181818;   
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-navigation-prev * p, .project-navigation-next * p {
    padding-right: 1em;
}

@media screen and (min-width: 450px) {
    .project-navigation {
        justify-content: space-between;
        flex-direction: row;        
    }
}

@media screen and (max-width: 450px) {
    .project-navigation-prev {
        margin-bottom: 2em;
    }
}