.recent-post {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  margin-bottom: 1em;
  margin-top: 1em;

  &:hover {
    cursor: pointer;
  }
}

.recent-post-circle {
  display: flex;
  justify-items: center;
  align-items: center;
  max-width: 150px;
  padding: 0.3em;
}

.recent-post-circle img {
  width: 70px;
  position: relative;
  left: 1.5em;
  top: 0.2em;
}

.recent-post-title {
  color: white;
}

.recent-post-date {
  color: #6A6A6A;
}

@media screen and (min-width: 640px) {
  .recent-post-circle {
    display: flex;
    justify-items: center;
    align-items: center;
    max-width: 100px;
    padding: 0;
  }

  .recent-post {
    grid-template-columns: minmax(0, 1fr) minmax(0, 0.75fr);
  }

  .recent-post-circle img {
    width: 45px;
    position: relative;
    left: 1.5em;
    top: 0.2em;
  }

  .recent-post {
    gap: 0;
  }
}

@media screen and (min-width: 640px) and (max-width: 900px) {
  .recent-post-info {
    font-size: 14px;
  }

  .recent-post {
    gap: 10px;
  }
}

@media screen and (min-width: 640px) and (max-width: 840px) {
  .recent-post-info {
    font-size: 12px;
  }
}

@media screen and (min-width: 1100px) {
  .recent-post {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
    gap: 10px;
  }
}