@import '../../../constants/colors';

.digital-marketing * {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
}

.digital-marketing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.digital-marketing > div {
  margin-top: 3em;
}