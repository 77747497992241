@import '../../../constants/colors';

.container {
  font-family: 'Manrope', sans-serif;

  .contactLink {
    text-decoration: none;
    color: black;
    position: relative;
    bottom: -0.3em;
  }

  .socialIcons {
    display: flex;
    align-items: center;
  }

  .socialIconsItem {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: .8rem;
    padding: 0.5rem;
    border: 1px solid #101010;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
    transition: 250ms ease;
    cursor: pointer;
  }

  .socialIconsItemHref {
    color: black;
    text-decoration: none;
    background: white;
    height: 100%;
    width: 100%;
  }

  .socialIconsItem:not(:last-child) {
    margin-right: 0.5rem;
  }

  .empty {
    width: 100%;
    background-color: black;
    position: absolute;
    height: 26vh;
    border-bottom-left-radius: 20%;
    border-bottom-right-radius: 20%;
  }

  .footer {
    padding-top: 17em;
    background-color: white;
  }

  .logoBox {
    background-color: white;

    .headLine {
      color: $mainGreen;
      margin-top: 0;
      font-size: 1.2rem;
      font-weight: bold;
    }

    .headLine,
    .link {
      margin-left: 1.5em;
    }
  }

  .listBox {

    .quickLinksTitle,
    .contactUsTitle,
    .socialMediaTitle {
      font-family: 'Manrope', 'Heebo', sans-serif;
      font-size: 1.875rem;
      margin-left: 1em;
    }

    .quickLinks {

      .quickLinksListItem {
        font-size: 0.875rem;
        margin-top: 18px;
      }
    }

    .socialMedia {

      .socialMediaListItem {
        width: 31px;
        height: 31px;
        display: inline-block;
        margin-right: 23px;

        .icon {
          width: 100%;
          height: 100%;
        }
      }
    }

    .quickLinks,
    .socialMedia,
    .contactUs {
      list-style: none;
    }
  }

  .cite {
    display: flex;
    align-items: center;

    .citeIcon {
      position: relative;
      bottom: 3px;
      width: 18px;
      aspect-ratio: 13 / 18;
    }

    .citeText {
      font-size: 0.875rem;
      font-weight: bold;
      margin-left: 12px;
    }
  }

  .link {
    text-decoration: none;
    color: black;
    font-weight: bold;
  }

}

.contactUsListItem {
  margin-top: 17px;
}

.list1,
.list2 {
  list-style: none;
  margin: 0;
}

.list2 {
  position: relative;
  top: 1px;
  right: 1.2em;
}

.twoLists {
  display: flex;
}

.contactUsTitle {
  margin-bottom: 0.4em;
}

.cite {
  margin-left: 3em;
}

@media screen and (min-width: 640px) {
  .container {
    .footer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 0.25fr;
    }
  }
}

@media screen and (min-width: 952px) {
  .container {
    .footer {
      display: grid;
      grid-template-columns: 0.75fr repeat(3, 1fr);
      grid-template-rows: 1fr 1fr 0.25fr;
    }

    .cite {
      grid-row: 3;
      grid-column: 4;
    }

    .quickLinksBox {
      grid-row: 1 / 4;
    }

    .logoBox {
      grid-row: 1;
      margin-top: 3rem;
    }

    .listBox {
      grid-row: 1 / 3;
    }
  }
}