@import '../../../constants/colors';

.container * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Heebo, sans-serif;
}

.container {
  position: fixed;
  z-index: 80;
  width: 70%;
  max-width: 300px;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5em;
  right: -500%;
  top: 0;
}

.container > svg {
  position: fixed;
  top: 3em;
  right: 3em;

  &:hover {
    cursor: pointer;
  }
}

.nav {
  display: flex;
  flex-direction: column;
}

.blogs {
  position: relative;
  top: 1em;
}

.shadow {
  z-index: 70;
  position: fixed;
  background-color: rgba(black, 0.3);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.link {
  text-decoration: none;
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 0.3em;
}

.link1:hover {
  color: $mainGreen;
  transition: all 0.3s;
}

.link2:hover {
  color: #1A9E53;
  transition: all 0.3s;
}

.link3:hover {
  color: #20A46D;
  transition: all 0.3s;
}

.link4:hover {
  color: #25A987;
  transition: all 0.3s;
}

.link5:hover {
  color: #2BAEA1;
  transition: all 0.3s;
}

.link6:hover {
  color: #30B3BB;
  transition: all 0.3s;
}

.link7:hover {
  color: #36B9D5;
  transition: all 0.3s;
}

.link8:hover {
  color: $mainBlue;
  transition: all 0.3s;
}

.menu-open {
  animation: menu-open-animation 0.3s ease-in-out forwards;
}

.menu-open svg {
  animation: svg-open-animation 0.3s ease-out forwards;
}

.menu-close {
  animation: menu-close-animation 1s linear forwards;
}

@keyframes menu-open-animation {
  from {
    right: -500%;
  }

  to {
    right: 0;
  }
}

@keyframes menu-close-animation {
  from {
    right: 0;
  }

  to {
    right: -500%;
  }
}

@keyframes svg-open-animation {
  from {
    right: -500%;
  }

  to {
    right: 3em;
  }
}