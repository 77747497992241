$contact-form-bg-color: #101010;

.container {
  background-color: $contact-form-bg-color;
  border-radius: 31px;
  padding: 3em;
}

.form {
  color: white;
  max-width: 700px;
}

.form > label, .form > input, .button {
  font-family: 'Heebo', sans-serif;
}

.form > input, textarea {
  display: block;
  background-color: inherit;
  border: none;
  outline: none;
  width: 100%;
  color: white;
  padding: 0.5em;
  border-bottom: 1px solid rgba(white,0.1);
  font-size: 1rem;
  margin-bottom: 2em;
}

.button {
  margin-top: 1em;
}