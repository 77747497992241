.about-team {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-team img {
  margin-top: 5em;
}

@media screen and (min-width: 720px) {
  .about-team > div {
    display: grid;
    grid-template-columns: repeat(2,minmax(0,600px));
    gap: 50px;
  }
}

@media screen and (min-width: 1100px) {
  .about-team > div {
    display: grid;
    grid-template-columns: repeat(3,minmax(0,600px));
  }
}