.services-nav * {
  font-family: 'Heebo', sans-serif;
  color: rgba(white, 0.5);
}

.services-nav {
  margin-top: 1em;
  margin-bottom: 2em;
}

.services-nav ul {
  list-style: none;
  margin-left: 1em;
  margin-top: 6em;
}

.services-nav-link {
  color: rgba(white, 0.5);
  text-decoration: none;

  &:before {
    content: "•";
    margin-right: 0.3em;
  }

  &:hover {
    cursor: pointer;
  }
}

.services-nav-link-current {
  color: white;
  margin-right: 0.5em;
}

.services-nav-link-wrapper {
  display: flex;
  align-items: center;
}

.services-nav-line {
  animation: line 0.3s ease-in-out forwards;
  height: 2px;
  background-color: white;
}

@media screen and (min-width: 440px) {
  .services-nav {
    width: 100vw;
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 1024px) {
  .services-nav {
    width: 90vw;
  }

  .services-nav ul {
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .services-nav ul li {
    margin-right: 1em;
  }
}

@keyframes line {
  from {
    width: 0;
  }

  to {
    width: 100px;
  }
}