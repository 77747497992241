@import '../../../constants/colors';

.services.imageBox:nth-child(odd) {
  background-color: aqua;
}

.services-item:nth-child(odd) {
  display: flex;
  align-items: center;
  justify-content: space-around;

}

.image3 {
  padding: 3rem;
}

.services-item:nth-child(even) {
  display: flex;
  justify-content: space-around;
  flex-direction: row-reverse;

}

.servicesContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;

  .text {
    width: 40vw;
    display: flex;
    padding-top: 20%;
    flex-direction: column;
    justify-content: center;
  }

  .title {
    color: $mainBlue;
    font-size: 3.75rem;
  }

  .head, .subhead {
    color: white;
    margin-top: 0.3em;
  }

  .title, .head {
    margin-bottom: 0;
  }

  .head {
    font-weight: bold;
    font-size: 2rem;
  }

  .subhead {
    font-weight: 300;
    font-size: 1.25rem
  }
}

.imageBox {
  display: flex;
  justify-content: center;
  align-items: center;

  .image1 {
    width: 45vw;
  }

  .image2 {
    width: 45vw;
  }

  .image3 {
    position: relative;
    left: -3.8vw;
    width: 50vw;
  }

  .image4 {
    width: 30vw;
  }
}

@media screen and (max-width: 1024px) {
  .services-item:nth-child(odd) {
    display: flex;
    flex-direction: column;
  }
  .services-item:nth-child(even) {
    display: flex;
    flex-direction: column;
  }
  .imageBox {
    .image1, .image2 {
      width: 70vw;
    }

    .image3 {
      position: static;
      width: 100%;
    }

    .image4 {
      width: 50vw;
    }
  }

  .servicesContent {
    // background-color: aqua;
    width: 100%;
    display: flex;
    align-self: stretch;

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      padding-top: 5rem;
    }
  }
}

@media screen and (max-width: 667px) {
  .imageBox {
    .image1, .image2, .image4 {
      width: 300px;
    }
  }

  .servicesContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      color: $mainBlue;
      font-size: 2.3rem;
    }

    .head, .subhead {
      color: white;
    }

    .title, .head {
      margin-bottom: 0;
    }

    .head {
      font-weight: bold;
      font-size: 1.5rem;
    }

    .subhead {
      font-weight: 300;
      font-size: 1rem
    }
  }
}