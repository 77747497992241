@import '../../../constants/colors';

.about-introduction {
  margin-top: 6em;
}

.about-introduction-paragraph {
  padding: 1em 1em 0.5em;
}

.about-introduction-second-paragraph {
  padding: 0.5em 1em 1em;
}

.about-introduction-headline {
  color: $mainGreen;
  padding-left: 1em;
  padding-right: 1em;
}

.about-introduction ul {
  list-style: inside;
  color: white;
}

.about-introduction li {
  padding: 1em;
}

.about-introduction-aside {
  display: flex;
  justify-content: center;
  margin-top: 2.2em;
  flex-direction: column;
}

@media screen and (min-width: 640px) {
  .about-introduction {
    display: grid;
    grid-template-columns: repeat(2,minmax(0,600px));
  }
}

@media screen and (min-width: 1024px) {
  .about-introduction p, .about-introduction li {
    font-size: 1.3rem;
  }

  .about-introduction-headline {
    font-size: 2rem;
  }

  .about-introduction {
    grid-template-columns: repeat(2,minmax(0,800px));
  }
}

@media screen and (min-width: 1440px) {
  .about-introduction p, .about-introduction li {
    font-size: 1.4rem;
  }

  .about-introduction-headline {
    margin-top: 1.7em;
    font-size: 2.1rem;
  }
}