.blog-content {
  color: white;
}

.blog-content img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.blog-content a {
  color: white;
  text-decoration: none;
}

//.blog-content-image {
//  width: 100%;
//  border: none;
//  background-size: contain;
//  background-position: center;
//  background-repeat: no-repeat;
//}

.blog-statistics {
  margin-left: -2.3em;
}

.blog-statistics>li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  max-width: 100px;
}

.blog-statistics>li>svg {
  margin-right: 0.5em;
}

.list-line {
  background-color: white;
  width: 1px;
  height: 3vh;
  opacity: 0.2;
}

.blog-content-title {
  font-size: 2rem;
}

@media screen and (min-width: 640px) {
  .blog-statistics {
    margin-left: -2em;
    max-width: 300px;
  }
}