@import '../../constants/colors';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle {
  background: linear-gradient(#3BBEEF,$mainGreen,black);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  min-width: 250px;
  max-width: 450px;
  min-height: 250px;
  max-height: 450px;
  margin-bottom: 1em;

  &:hover {
    cursor: pointer;
    
    img {
      scale: 1.03;
    }
  }

}

.wrapper * {
  transition: all 0.3s;
}

.circle > svg, .circle > img {
  min-width: 220px;
  width: 100%;
  max-width: 300px;
  background-color: black;
  border-radius: 50%;
  margin: 1em;
  padding: 1em;
  object-fit: cover;
  object-position: center;
}

.title {
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  max-width: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
}
