.project-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3em 0;
}

.project-section h2 {
    text-align: start;
    padding: 0 3em;
}

.project-section-image-box {
    width: 100%;
    margin-top: 3em;
    margin-bottom: 3em;
}

@media screen and (min-width: 900px) {
    .project-section {
        padding: 6em;
    }
}