.project-hero {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 99vw;
    height: 100vh;
}

.shadow {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
}

.project-hero * {
    color: white;
    z-index: 0;
}

.project * h2 {
    text-align: center;
    font-size: 1.3rem;
}

.project-hero-url {
    margin-top: 3em;
}