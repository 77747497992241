.header {
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Manrope', sans-serif;
  position: fixed;
  width: 100%;
  z-index: 60;
  padding-left: 1em;
  top: 0;
}

.header > svg {
  margin-right: 3em;

  &:hover {
    cursor: pointer;
  }
}
