.shadow {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  //background-color: rgba(black, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 90;
}

.container {
  animation: container-popup 0.2s ease-in-out forwards;
}

@keyframes container-popup {
  from {
    opacity: 0;
    margin-top: 3em;
  }

  to {
    opacity: 1;
    margin-top: 0;
  }
}