.portfolio, .portfolio * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Manrope, sans-serif;
}

.portfolio {
    margin-top: 10em;
}

.portfolio-header > h1 {
    padding-top: 1em;
    text-align: center;
    margin-bottom: 1em;
    font-size: 3rem;
    text-shadow:
          1px 1px 0 #28ac94,
          -1px -1px 0 #28ac94,
          1px -1px 0 #28ac94,
          -1px 1px 0 #28ac94,
          1px 1px 0 #28ac94;
}

@media screen and (min-width: 750px) {
    .portfolio h1 {
      font-size: 5rem;
    }
}