.privacy-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #414141;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.15);
  border-radius: 9px;
  transition: all 0.3s;
  padding-top: 0.3em;
  padding-left: 0.3em;
}

.privacy-card img {
  max-width: 40px;
  min-height: 32px;
}

.privacy-card p {
  font-size: 10px;
  font-weight: bold;
}

.privacy-card-active {
  background-color: white;
}

@media screen and (min-width: 500px) {
  .privacy-card img {
    max-width: 50px;
    min-height: 40px;
  }

  .privacy-card p {
    font-size: 13px;
    font-weight: normal;
  }
}

@media screen and (min-width: 640px) {
  .privacy-card img {
    max-width: 60px;
    min-height: 64px;
  }

  .privacy-card p {
    font-size: 1rem;
    font-weight: normal;
  }
}

@media screen and (min-width: 1440px) {
  .privacy-card img {
    max-width: 80px;
    min-height: 80px;
  }

  .privacy-card {
    padding: 1.5em;
  }

  .privacy-card p {
    font-size: 1.2rem;
    font-weight: normal;
  }
}