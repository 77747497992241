@import '../../constants/colors';

.blogs * {
  font-family: 'Marope', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.blog-link {
  margin-bottom: 3em;
}

.blogs-header > h1 {
  color: $mainGreen;
  font-size: 4rem;

  @media screen and (max-width: 640px) {
      margin-bottom: 0.5rem;
  }
}

.blogs-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  margin: 7em 3em 3em;
  padding: 1em;
  border-bottom: 0.5px solid rgba(#ffff, 0.2);
}

@media screen and (min-width: 640px) {
  .blogs-content {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2,minmax(0,1fr));
    margin-top: 5em;
  }

  .blog-link {
    margin-bottom: 0;
  }

  .blogs-header {
    flex-direction: row;
  }
}

@media screen and (min-width: 1024px) {
  .blogs-content {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3,minmax(0,1fr));
  }
} 