@import '../../constants/colors';

.container {
  display: flex;
  align-items: center;
}

.container button {
  border: none;
  background: inherit;
  color: $mainGreen;
  text-transform: uppercase;
  border: 1px solid $mainGreen;
  background-color: white;
  margin-left: 1em;
  padding: 0.4em 0.6em;
  border-radius: 2em;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}

.container input {
  border: none;
  background-color: black;
  color: #159939;
  border-bottom: 1px solid #159939;
  outline: none;
  width: 100%;
  padding-bottom: 0.5em;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
}

.container input, .list {
  max-width: 300px;
}

.list {
  width: 100%;
  position: absolute;
  z-index: 10;
  background-color: black;
}

.list > * {
  margin-bottom: 0.5em;
}