$black: hsl(0, 0%, 13%);

.container > * {
    background: $black;
    color: white;
    width: 100%;
    width: 200px;
    padding: 1em;
    border-radius: 31px;
}

.current {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.options > div, .current {
    &:hover {
      cursor: pointer;
    }
}

.options {
    position: absolute;
    margin-top: 0.3em;
    color: white;
    background-color: $black;

    div {
        margin-bottom: 0.5em;
    }
}

